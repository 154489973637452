<template>
  <div class="absolute inset-0 z-40 flex items-center justify-center p-24">
    <div class="inset-0 z-40 absolute bg-black opacity-50"></div>
    <div
      class="w-full bg-white h-full shadow-2xl z-40 px-10 pt-10 flex flex-col rounded overflow-hidden"
    >
      <div class="w-full flex items-center">
        <div>
          <h3
            class="uppercase text-lg font-semibold text-primary tracking-wide"
          >
            {{
              attachment.name ? attachment.name : attachment.original_filename
            }}
          </h3>
          <h4 v-if="attachment.name" class="text-gray-500">
            {{ attachment.original_filename }}
          </h4>
        </div>
        <hr class="flex-1 border-primary ml-4 mr-10" />
        <button
          @click.prevent="close"
          class="flex items-center bg-white text-secondary p-2 font-semibold rounded border border-secondary hover:bg-gray-200 hover:shadow active:shadow-inner transition duration-100"
        >
          <svg
            class="stroke-current h-6 w-6"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M18 6L6 18M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex-1 flex flex-col max-h-full -mx-10 relative bg-gray-300">
        <iframe
          v-if="attachmentUrl"
          class="h-full w-full"
          :src="attachmentUrl"
          allowfullscreen
          webkitallowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewAttachment",
  props: {
    attachment: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      attachmentUrl: false,
    };
  },
  mounted() {
    this.attachmentUrl = "/ViewerJS/#" + this.attachment.access_url;
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
